import React, { useState, useCallback } from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import classNames from 'classnames';

import { useIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import {
  isAnyFilterActive,
  isMainSearchTypeKeywords,
  isOriginInUse,
  getQueryParamNames,
} from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';

import { H3, H5, ModalInMobile, Page } from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import SearchForm from './SearchPageSearchForm/SearchForm';

import { setActiveListing } from './SearchPage.duck';
import {
  groupListingFieldConfigs,
  initialValues,
  searchParamsPicker,
  validUrlQueryParamsFromProps,
  validFilterParams,
  cleanSearchFromConflictingParams,
  createSearchResultSchema,
  pickListingFieldFilters,
  omitLimitedListingFieldParams,
} from './SearchPage.shared';

import FilterComponent from './FilterComponent';
import SearchMap from './SearchMap/SearchMap';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersSecondary from './SearchFiltersSecondary/SearchFiltersSecondary';
import SearchFiltersPrimary from './SearchFiltersPrimary/SearchFiltersPrimary';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SortBy from './SortBy/SortBy';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import NoSearchResultsMaybe from './NoSearchResultsMaybe/NoSearchResultsMaybe';

import css from './SearchPage.module.css';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

const SearchPageComponent = props => {
  const {
    listings: listingsFromProps = [],
    onActivateListing,
    onManageDisableScrolling,
    pagination = null,
    scrollingDisabled,
    searchInProgress,
    searchListingsError = null,
    searchParams = {},
    activeListingId = null,
    reviews = {},
  } = props;

  // filter out unsubbed acuity stylists - sayo
  const listings = listingsFromProps.filter(listing => {
    const isNotAcuityStylist = listing.author.attributes.profile.publicData.Acuity_yes_no === 'No';
    const isSubscribedAcuityStylist =
      listing.author.attributes.profile.publicData.acuityStylistSubscribed !== false;
    return isNotAcuityStylist || isSubscribedAcuityStylist;
  });

  const [isSearchMapOpenOnMobile, setIsSearchMapOpenOnMobile] = useState(false);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const [currentQueryParams, setCurrentQueryParams] = useState(validUrlQueryParamsFromProps(props));
  const [isSecondaryFiltersOpen, setIsSecondaryFiltersOpen] = useState(false);

  const intl = useIntl();
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const history = useHistory();
  const location = useLocation();

  const { listingFields } = config?.listing || {};
  const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

  const activeListingTypes = config?.listing?.listingTypes.map(config => config.listingType);
  const marketplaceCurrency = config.currency;
  const categoryConfiguration = config.categoryConfiguration;
  const listingCategories = categoryConfiguration.categories;
  const listingFieldsConfig = pickListingFieldFilters({
    listingFields,
    locationSearch: location.search,
    categoryConfiguration,
  });
  const filterConfigs = {
    listingFieldsConfig,
    defaultFiltersConfig,
    listingCategories,
  };

  const onMapMoveEnd = useCallback(
    debounce((viewportBoundsChanged, data) => {
      const { viewportBounds, viewportCenter } = data;

      const routes = routeConfiguration;
      const searchPagePath = pathByRouteName('SearchPage', routes);
      const currentPath =
        typeof window !== 'undefined' && window.location && window.location.pathname;

      // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
      const isSearchPage = currentPath === searchPagePath;

      // If mapSearch url param is given
      // or original location search is rendered once,
      // we start to react to "mapmoveend" events by generating new searches
      // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
      if (viewportBoundsChanged && isSearchPage) {
        const { address, bounds, mapSearch, ...rest } = parse(location.search, {
          latlng: ['origin'],
          latlngBounds: ['bounds'],
        });

        const originMaybe = isOriginInUse(config) ? { origin: viewportCenter } : {};
        const dropNonFilterParams = false;

        const searchParams = {
          address,
          ...originMaybe,
          bounds: viewportBounds,
          mapSearch: true,
          ...validFilterParams(rest, filterConfigs, dropNonFilterParams),
        };

        history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
      }
    }, SEARCH_WITH_MAP_DEBOUNCE),
    [config, filterConfigs, history, location.search, routeConfiguration]
  );

  const onOpenMobileModal = () => {
    setIsMobileModalOpen(true);
  };

  const onCloseMobileModal = () => {
    setIsMobileModalOpen(false);
  };

  const applyFilters = () => {
    const urlQueryParams = validUrlQueryParamsFromProps(props);
    const searchParams = { ...urlQueryParams, ...currentQueryParams };
    const search = cleanSearchFromConflictingParams(searchParams, filterConfigs, sortConfig);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
  };

  const cancelFilters = () => {
    setCurrentQueryParams({});
  };

  const resetAll = e => {
    // Prevent default behavior if this function is used in an event handler
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    // Retrieve valid URL query parameters from props
    const urlQueryParams = validUrlQueryParamsFromProps(props);

    // Get the names of the query parameters related to filters
    const filterQueryParamNames = getQueryParamNames(listingFieldsConfig, defaultFiltersConfig);

    // Reset state by clearing current query parameters
    setCurrentQueryParams({});

    // Omit filter-related query parameters from the URL query parameters
    const queryParams = omit(urlQueryParams, filterQueryParamNames);

    // Update the URL to reflect the reset state
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  };

  const getHandleChangedValueFn = useCallback(
    useHistoryPush => {
      const urlQueryParams = validUrlQueryParamsFromProps(props);

      return updatedURLParams => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        const updatedQueryParams = omitLimitedListingFieldParams(
          {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
            address,
            bounds,
          },
          filterConfigs
        );

        setCurrentQueryParams(updatedQueryParams);

        if (useHistoryPush) {
          const search = cleanSearchFromConflictingParams(
            updatedQueryParams,
            filterConfigs,
            sortConfig
          );
          history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, search));
        }
      };
    },
    [config, currentQueryParams, filterConfigs, history, props, routeConfiguration, sortConfig]
  );

  const handleSortBy = (urlParam, values) => {
    const urlQueryParams = validUrlQueryParamsFromProps(props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
  };

  // Page transition might initially use values from previous search
  // urlQueryParams doesn't contain page specific url params
  // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
  const { searchParamsAreInSync, urlQueryParams, searchParamsInURL } = searchParamsPicker(
    location.search,
    searchParams,
    filterConfigs,
    sortConfig,
    isOriginInUse(config)
  );

  const validQueryParams = urlQueryParams;

  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
  const shouldShowSearchMap = !isMobileLayout || (isMobileLayout && isSearchMapOpenOnMobile);

  const isKeywordSearch = isMainSearchTypeKeywords(config);
  const builtInPrimaryFilters = defaultFiltersConfig.filter(f => ['categoryLevel'].includes(f.key));
  const builtInFilters = isKeywordSearch
    ? defaultFiltersConfig.filter(f => !['keywords', 'categoryLevel'].includes(f.key))
    : defaultFiltersConfig.filter(f => !['categoryLevel'].includes(f.key));
  const [customPrimaryFilters, customSecondaryFilters] = groupListingFieldConfigs(
    listingFieldsConfig,
    activeListingTypes
  );
  const availablePrimaryFilters = [
    ...builtInPrimaryFilters,
    ...customPrimaryFilters,
    ...builtInFilters,
  ];
  const availableFilters = [
    ...builtInPrimaryFilters,
    ...customPrimaryFilters,
    ...builtInFilters,
    ...customSecondaryFilters,
  ];

  const hasSecondaryFilters = !!(customSecondaryFilters && customSecondaryFilters.length > 0);

  // Selected aka active filters
  const selectedFilters = validQueryParams;
  const keysOfSelectedFilters = Object.keys(selectedFilters);
  const selectedFiltersCountForMobile = isKeywordSearch
    ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
    : keysOfSelectedFilters.length;
  const isValidDatesFilter =
    searchParamsInURL.dates == null ||
    (searchParamsInURL.dates != null && searchParamsInURL.dates === selectedFilters.dates);

  // Selected aka active secondary filters
  const selectedSecondaryFilters = hasSecondaryFilters
    ? validFilterParams(validQueryParams, {
        listingFieldsConfig: customSecondaryFilters,
        defaultFiltersConfig: [],
        listingCategories,
      })
    : {};
  const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length;

  const propsForSecondaryFiltersToggle = hasSecondaryFilters
    ? {
        isSecondaryFiltersOpen,
        toggleSecondaryFiltersOpen: isOpen => {
          setIsSecondaryFiltersOpen(isOpen);
          setCurrentQueryParams({});
        },
        selectedSecondaryFiltersCount,
      }
    : {};

  const hasPaginationInfo = !!pagination && pagination.totalItems != null;
  const totalItems =
    searchParamsAreInSync && hasPaginationInfo
      ? pagination.totalItems
      : pagination?.paginationUnsupported
      ? listings.length
      : 0;
  const listingsAreLoaded =
    !searchInProgress &&
    searchParamsAreInSync &&
    !!(hasPaginationInfo || pagination?.paginationUnsupported);

  const conflictingFilterActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    validQueryParams,
    filterConfigs
  );
  const sortBy = mode => {
    return sortConfig.active ? (
      <SortBy
        sort={validQueryParams[sortConfig.queryParamName]}
        isConflictingFilterActive={!!conflictingFilterActive}
        hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
        selectedFilters={selectedFilters}
        onSelect={handleSortBy}
        showAsPopup
        mode={mode}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;
  };
  const noResultsInfo = (
    <NoSearchResultsMaybe
      listingsAreLoaded={listingsAreLoaded}
      totalItems={totalItems}
      location={location}
      resetAll={resetAll}
    />
  );

  const { bounds, origin } = searchParamsInURL || {};
  const { title, description, schema } = createSearchResultSchema(
    listings,
    searchParamsInURL || {},
    intl,
    routeConfiguration,
    config
  );

  // Set topbar class based on if a modal is open in
  // a child component
  const topbarClasses = isMobileModalOpen
    ? classNames(css.topbarBehindModal, css.topbar)
    : css.topbar;

  // N.B. openMobileMap button is sticky.
  // For some reason, stickyness doesn't work on Safari, if the element is <button>
  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      description={description}
      title={title}
      schema={schema}
    >
      <TopbarContainer rootClassName={topbarClasses} currentSearchParams={validQueryParams} />
      <div className={css.container}>
        <div className={css.searchResultContainer}>
          <SearchFiltersMobile
            className={css.searchFiltersMobileMap}
            urlQueryParams={validQueryParams}
            sortByComponent={sortBy('mobile')}
            listingsAreLoaded={listingsAreLoaded}
            resultsCount={totalItems}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            showAsModalMaxWidth={MODAL_BREAKPOINT}
            onMapIconClick={() => setIsSearchMapOpenOnMobile(true)}
            onManageDisableScrolling={onManageDisableScrolling}
            onOpenModal={onOpenMobileModal}
            onCloseModal={onCloseMobileModal}
            resetAll={resetAll}
            selectedFiltersCount={selectedFiltersCountForMobile}
            noResultsInfo={noResultsInfo}
            isMapVariant
          >
            {availableFilters.map(filterConfig => {
              const key = `SearchFiltersMobile.${filterConfig.scope || 'built-in'}.${
                filterConfig.key
              }`;
              return (
                <FilterComponent
                  key={key}
                  idPrefix="SearchFiltersMobile"
                  config={filterConfig}
                  listingCategories={listingCategories}
                  marketplaceCurrency={marketplaceCurrency}
                  urlQueryParams={validQueryParams}
                  initialValues={initialValues(props, currentQueryParams)}
                  getHandleChangedValueFn={getHandleChangedValueFn}
                  intl={intl}
                  liveEdit
                  showAsPopup={false}
                />
              );
            })}
          </SearchFiltersMobile>
          <MainPanelHeader
            className={css.mainPanelMapVariant}
            sortByComponent={sortBy('desktop')}
            isSortByActive={sortConfig.active}
            listingsAreLoaded={listingsAreLoaded}
            resultsCount={totalItems}
            searchInProgress={searchInProgress}
            searchListingsError={searchListingsError}
            noResultsInfo={noResultsInfo}
          >
            <div>
              <SearchFiltersPrimary {...propsForSecondaryFiltersToggle}>
                {availablePrimaryFilters.map(filterConfig => {
                  const key = `SearchFiltersPrimary.${filterConfig.scope || 'built-in'}.${
                    filterConfig.key
                  }`;
                  return (
                    <FilterComponent
                      key={key}
                      idPrefix="SearchFiltersPrimary"
                      config={filterConfig}
                      listingCategories={listingCategories}
                      marketplaceCurrency={marketplaceCurrency}
                      urlQueryParams={validQueryParams}
                      initialValues={initialValues(props, currentQueryParams)}
                      getHandleChangedValueFn={getHandleChangedValueFn}
                      intl={intl}
                      showAsPopup
                      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                    />
                  );
                })}
              </SearchFiltersPrimary>
            </div>
          </MainPanelHeader>
          {isSecondaryFiltersOpen ? (
            <div className={classNames(css.searchFiltersPanel)}>
              <SearchFiltersSecondary
                urlQueryParams={validQueryParams}
                listingsAreLoaded={listingsAreLoaded}
                applyFilters={applyFilters}
                cancelFilters={cancelFilters}
                resetAll={resetAll}
                onClosePanel={() => setIsSecondaryFiltersOpen(false)}
              >
                {customSecondaryFilters.map(filterConfig => {
                  const key = `SearchFiltersSecondary.${filterConfig.scope || 'built-in'}.${
                    filterConfig.key
                  }`;
                  return (
                    <FilterComponent
                      key={key}
                      idPrefix="SearchFiltersSecondary"
                      config={filterConfig}
                      listingCategories={listingCategories}
                      marketplaceCurrency={marketplaceCurrency}
                      urlQueryParams={validQueryParams}
                      initialValues={initialValues(props, currentQueryParams)}
                      getHandleChangedValueFn={getHandleChangedValueFn}
                      intl={intl}
                      showAsPopup={false}
                    />
                  );
                })}
              </SearchFiltersSecondary>
            </div>
          ) : (
            <div
              className={classNames(css.listingsForMapVariant, {
                [css.newSearchInProgress]: !(listingsAreLoaded || searchListingsError),
              })}
            >
              {searchListingsError ? (
                <H3 className={css.error}>
                  <FormattedMessage id="SearchPage.searchError" />
                </H3>
              ) : null}
              {!isValidDatesFilter ? (
                <H5>
                  <FormattedMessage id="SearchPage.invalidDatesFilter" />
                </H5>
              ) : null}
              <SearchResultsPanel
                className={css.searchListingsPanel}
                listings={listings}
                pagination={listingsAreLoaded ? pagination : null}
                search={parse(location.search)}
                setActiveListing={onActivateListing}
                isMapVariant
                reviews={reviews}
              />
            </div>
          )}
        </div>
        <ModalInMobile
          className={css.mapPanel}
          id="SearchPage.map"
          isModalOpenOnMobile={isSearchMapOpenOnMobile}
          onClose={() => setIsSearchMapOpenOnMobile(false)}
          showAsModalMaxWidth={MODAL_BREAKPOINT}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.mapWrapper} data-testid="searchMapContainer">
            {shouldShowSearchMap ? (
              <SearchMap
                reusableContainerClassName={css.map}
                activeListingId={activeListingId}
                bounds={bounds}
                center={origin}
                isSearchMapOpenOnMobile={isSearchMapOpenOnMobile}
                location={location}
                listings={listings || []}
                onMapMoveEnd={onMapMoveEnd}
                onCloseAsModal={() => {
                  onManageDisableScrolling('SearchPage.map', false);
                }}
                messages={intl.messages}
              />
            ) : null}
          </div>
        </ModalInMobile>
      </div>
    </Page>
  );
};

SearchPageComponent.propTypes = {
  listings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <SearchPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    reviews,
  } = state.SearchPage;
  const listings = getListingsById(state, currentPageResultIds);
  return {
    listings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    reviews,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedSearchPage);

export default SearchPage;
