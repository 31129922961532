import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import differenceBy from 'lodash/differenceBy';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';

import { types as sdkTypes } from '../../../util/sdkLoader';
import { parse } from '../../../util/urlHelpers';
import { propTypes } from '../../../util/types';
import { ensureListing } from '../../../util/data';
import { sdkBoundsToFixedCoordinates, hasSameSDKBounds } from '../../../util/maps';

import SearchMapPriceLabel from '../SearchMapPriceLabel/SearchMapPriceLabel';
import SearchMapInfoCard from '../SearchMapInfoCard/SearchMapInfoCard';
import SearchMapGroupLabel from '../SearchMapGroupLabel/SearchMapGroupLabel';
import { groupedByCoordinates, reducedToArray } from './SearchMap.helpers';
import css from './SearchMapWithMapbox.module.css';

export const LABEL_HANDLE = 'SearchMapLabel';
export const INFO_CARD_HANDLE = 'SearchMapInfoCard';
export const SOURCE_AUTOCOMPLETE = 'autocomplete';
const BOUNDS_FIXED_PRECISION = 8;

const { LatLng: SDKLatLng, LatLngBounds: SDKLatLngBounds } = sdkTypes;

/**
 * Fit part of map (descriped with bounds) to visible map-viewport
 *
 * @param {Object} map - map that needs to be centered with given bounds
 * @param {SDK.LatLngBounds} bounds - the area that needs to be visible when map loads.
 */
export const fitMapToBounds = (map, bounds, options) => {
  const { padding = 0, isAutocompleteSearch = false } = options;

  // map bounds as string literal for google.maps
  const mapBounds = sdkBoundsToMapboxBounds(bounds);
  const paddingOptionMaybe = padding == null ? { padding } : {};
  const eventData = isAutocompleteSearch ? { searchSource: SOURCE_AUTOCOMPLETE } : {};

  // If bounds are given, use it (defaults to center & zoom).
  if (map && mapBounds) {
    map.fitBounds(mapBounds, { ...paddingOptionMaybe, linear: true, duration: 0 }, eventData);
  }
};

/**
 * Convert Mapbox formatted LatLng object to Sharetribe SDK's LatLng coordinate format
 * Longitudes > 180 and < -180 are converted to the correct corresponding value
 * between -180 and 180.
 *
 * @param {LngLat} mapboxLngLat - Mapbox LngLat
 *
 * @return {SDKLatLng} - Converted latLng coordinate
 */
export const mapboxLngLatToSDKLatLng = lngLat => {
  const mapboxLng = lngLat.lng;

  // For bounding boxes that overlap the antimeridian Mapbox sometimes gives
  // longitude values outside -180 and 180 degrees.Those values are converted
  // so that longitude is always between -180 and 180.
  const lng = mapboxLng > 180 ? mapboxLng - 360 : mapboxLng < -180 ? mapboxLng + 360 : mapboxLng;

  return new SDKLatLng(lngLat.lat, lng);
};

/**
 * Convert Mapbox formatted bounds object to Sharetribe SDK's bounds format
 *
 * @param {LngLatBounds} mapboxBounds - Mapbox LngLatBounds
 *
 * @return {SDKLatLngBounds} - Converted bounds
 */
export const mapboxBoundsToSDKBounds = mapboxBounds => {
  if (!mapboxBounds) {
    return null;
  }

  const ne = mapboxBounds.getNorthEast();
  const sw = mapboxBounds.getSouthWest();
  return new SDKLatLngBounds(mapboxLngLatToSDKLatLng(ne), mapboxLngLatToSDKLatLng(sw));
};

/**
 * Convert sdk bounds that overlap the antimeridian into values that can
 * be passed to Mapbox. This is achieved by converting the SW longitude into
 * a value less than -180 that flows over the antimeridian.
 *
 * @param {SDKLatLng} bounds - bounds passed to the map
 *
 * @return {LngLatBoundsLike} a bounding box that is compatible with Mapbox
 */
const sdkBoundsToMapboxBounds = bounds => {
  if (!bounds) {
    return null;
  }
  const { ne, sw } = bounds;

  // if sw lng is > ne lng => the bounds overlap antimeridian
  // => flip the nw lng to the negative side so that the value
  // is less than -180
  const swLng = sw.lng > ne.lng ? -360 + sw.lng : sw.lng;

  return [[swLng, sw.lat], [ne.lng, ne.lat]];
};

/**
 * Return map bounds as SDKBounds
 *
 * @param {Mapbox} map - Mapbox map from where the bounds are asked
 *
 * @return {SDKLatLngBounds} - Converted bounds of given map
 */
export const getMapBounds = map => mapboxBoundsToSDKBounds(map.getBounds());

/**
 * Return map center as SDKLatLng
 *
 * @param {Mapbox} map - Mapbox map from where the center is asked
 *
 * @return {SDKLatLng} - Converted center of given map
 */
export const getMapCenter = map => mapboxLngLatToSDKLatLng(map.getCenter());

/**
 * Check if map library is loaded
 */
export const isMapsLibLoaded = () =>
  typeof window !== 'undefined' && window.mapboxgl && window.mapboxgl;

/**
 * Return price labels grouped by listing locations.
 * This is a helper function for SearchMapWithMapbox component.
 */
const priceLabelsInLocations = (
  listings,
  activeListingId,
  infoCardOpen,
  onListingClicked,
  mapComponentRefreshToken
) => {
  const listingArraysInLocations = reducedToArray(groupedByCoordinates(listings));
  const priceLabels = listingArraysInLocations.reverse().map(listingArr => {
    const isActive = activeListingId
      ? !!listingArr.find(l => activeListingId.uuid === l.id.uuid)
      : false;

    // If location contains only one listing, print price label
    if (listingArr.length === 1) {
      const listing = listingArr[0];
      const infoCardOpenIds = Array.isArray(infoCardOpen)
        ? infoCardOpen.map(l => l.id.uuid)
        : infoCardOpen
        ? [infoCardOpen.id.uuid]
        : [];

      // if the listing is open, don't print price label
      if (infoCardOpen != null && infoCardOpenIds.includes(listing.id.uuid)) {
        return null;
      }

      // Explicit type change to object literal for Google OverlayViews (geolocation is SDK type)
      const { geolocation } = listing.attributes;

      const key = listing.id.uuid;
      return {
        markerId: `price_${key}`,
        location: geolocation,
        type: 'price',
        componentProps: {
          key,
          isActive,
          className: LABEL_HANDLE,
          listing,
          onListingClicked,
          mapComponentRefreshToken,
        },
      };
    }

    // Explicit type change to object literal for Google OverlayViews (geolocation is SDK type)
    const firstListing = ensureListing(listingArr[0]);
    const geolocation = firstListing.attributes.geolocation;

    const key = listingArr[0].id.uuid;
    return {
      markerId: `group_${key}`,
      location: geolocation,
      type: 'group',
      componentProps: {
        key,
        isActive,
        className: LABEL_HANDLE,
        listings: listingArr,
        onListingClicked,
        mapComponentRefreshToken,
      },
    };
  });
  return priceLabels;
};

/**
 * Return info card. This is a helper function for SearchMapWithMapbox component.
 */
const infoCardComponent = (
  infoCardOpen,
  onListingInfoCardClicked,
  createURLToListing,
  mapComponentRefreshToken
) => {
  const listingsArray = Array.isArray(infoCardOpen) ? infoCardOpen : [infoCardOpen];

  if (!infoCardOpen) {
    return null;
  }

  const firstListing = ensureListing(listingsArray[0]);
  const key = firstListing.id.uuid;
  const geolocation = firstListing.attributes.geolocation;

  return {
    markerId: `infoCard_${key}`,
    location: geolocation,
    key, // Separate key prop
    componentProps: {
      mapComponentRefreshToken,
      className: INFO_CARD_HANDLE,
      listings: listingsArray,
      onListingInfoCardClicked,
      createURLToListing,
    },
  };
};

const SearchMapWithMapbox = ({
  id,
  className,
  listings,
  activeListingId,
  infoCardOpen,
  onListingClicked,
  onListingInfoCardClicked,
  createURLToListing,
  mapComponentRefreshToken,
  config,
  location,
  onMapMoveEnd,
  onMapLoad,
  bounds,
  zoom,
  reusableMapHiddenHandle,
  onClick,
}) => {
  const [mapContainer, setMapContainer] = useState(null);
  const [isMapReady, setIsMapReady] = useState(false);
  const [currentInfoCardRef, setCurrentInfoCardRef] = useState(null);
  const mapRef = useRef(null);
  const currentMarkersRef = useRef([]);
  const viewportBoundsRef = useRef(null);
  const prevLocationRef = useRef();

  const handleDoubleClickOnInfoCard = useCallback(e => {
    e.stopPropagation();
  }, []);

  const handleMobilePinchZoom = useCallback(e => {
    e.preventDefault();
    document.body.style.zoom = 0.99;
  }, []);

  const onMoveend = useCallback(() => {
    if (mapRef.current) {
      const isHiddenByReusableMap =
        reusableMapHiddenHandle &&
        mapContainer?.parentElement?.classList?.contains(reusableMapHiddenHandle);

      if (!isHiddenByReusableMap) {
        const viewportMapBounds = getMapBounds(mapRef.current);
        const viewportMapCenter = getMapCenter(mapRef.current);
        const viewportBounds = sdkBoundsToFixedCoordinates(
          viewportMapBounds,
          BOUNDS_FIXED_PRECISION
        );

        const viewportBoundsChanged =
          viewportBoundsRef.current && !hasSameSDKBounds(viewportBoundsRef.current, viewportBounds);

        onMapMoveEnd(viewportBoundsChanged, { viewportBounds, viewportMapCenter });
        viewportBoundsRef.current = viewportBounds;
      }
    }
  }, [onMapMoveEnd, reusableMapHiddenHandle, mapContainer]);

  const initializeMap = useCallback(() => {
    if (mapContainer && !mapRef.current) {
      const { offsetHeight, offsetWidth } = mapContainer;
      const hasDimensions = offsetHeight > 0 && offsetWidth > 0;
      if (hasDimensions) {
        mapRef.current = new window.mapboxgl.Map({
          container: mapContainer,
          style: 'mapbox://styles/mapbox/streets-v10',
          scrollZoom: false,
        });
        window.mapboxMap = mapRef.current;

        var nav = new window.mapboxgl.NavigationControl({ showCompass: false });
        mapRef.current.addControl(nav, 'top-left');

        mapRef.current.on('load', () => {
          setIsMapReady(true);
          onMapLoad(mapRef.current);
        });

        mapRef.current.on('moveend', onMoveend);
      }
    }
  }, [mapContainer, onMoveend, onMapLoad]);

  useEffect(() => {
    initializeMap();
  }, [initializeMap]);

  useEffect(() => {
    if (mapRef.current && isMapReady) {
      const currentBounds = getMapBounds(mapRef.current);
      if (!isEqual(bounds, currentBounds) && !viewportBoundsRef.current) {
        fitMapToBounds(mapRef.current, bounds, { padding: 0, isAutocompleteSearch: true });
      }
    }
  }, [bounds, isMapReady]);

  useEffect(() => {
    if (!isEqual(location, prevLocationRef.current)) {
      const { mapSearch } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });
      if (!mapSearch) {
        viewportBoundsRef.current = null;
      }
    }
    prevLocationRef.current = location;
  }, [location]);

  useEffect(() => {
    document.addEventListener('gesturestart', handleMobilePinchZoom, false);
    document.addEventListener('gesturechange', handleMobilePinchZoom, false);
    document.addEventListener('gestureend', handleMobilePinchZoom, false);

    return () => {
      if (currentInfoCardRef) {
        currentInfoCardRef.markerContainer.removeEventListener(
          'dblclick',
          handleDoubleClickOnInfoCard
        );
      }
      document.removeEventListener('gesturestart', handleMobilePinchZoom, false);
      document.removeEventListener('gesturechange', handleMobilePinchZoom, false);
      document.removeEventListener('gestureend', handleMobilePinchZoom, false);
    };
  }, [handleDoubleClickOnInfoCard, handleMobilePinchZoom]);

  const createMarker = useCallback(
    (data, markerContainer) => {
      if (mapRef.current && isMapReady) {
        return new window.mapboxgl.Marker(markerContainer, { anchor: 'bottom' })
          .setLngLat([data.location.lng, data.location.lat])
          .addTo(mapRef.current);
      }
      return null;
    },
    [isMapReady]
  );

  useEffect(() => {
    if (isMapReady) {
      // Create markers out of price labels and grouped labels
      const labels = priceLabelsInLocations(
        listings,
        activeListingId,
        infoCardOpen,
        onListingClicked,
        mapComponentRefreshToken
      );

      // If map has moved or info card opened, unnecessary markers need to be removed
      const removableMarkers = differenceBy(currentMarkersRef.current, labels, 'markerId');
      removableMarkers.forEach(rm => rm.marker && rm.marker.remove());

      // SearchMapPriceLabel and SearchMapGroupLabel:
      // create a new marker or use existing one if markerId is among previously rendered markers
      currentMarkersRef.current = labels
        .filter(v => v != null)
        .map(m => {
          const existingMarkerId = currentMarkersRef.current.findIndex(
            marker => m.markerId === marker.markerId && marker.marker
          );

          if (existingMarkerId >= 0) {
            const { marker, markerContainer, ...rest } = currentMarkersRef.current[
              existingMarkerId
            ];
            return { ...rest, ...m, markerContainer, marker };
          } else {
            const markerContainer = document.createElement('div');
            markerContainer.setAttribute('id', m.markerId);
            markerContainer.classList.add(css.labelContainer);
            const marker = createMarker(m, markerContainer);
            return { ...m, markerContainer, marker };
          }
        });

      /* Create marker for SearchMapInfoCard component */
      if (infoCardOpen) {
        const infoCard = infoCardComponent(
          infoCardOpen,
          onListingInfoCardClicked,
          createURLToListing,
          mapComponentRefreshToken
        );

        // marker container and its styles
        const infoCardContainer = document.createElement('div');
        infoCardContainer.setAttribute('id', infoCard.markerId);
        infoCardContainer.classList.add(css.infoCardContainer);
        infoCardContainer.addEventListener('dblclick', handleDoubleClickOnInfoCard, false);

        setCurrentInfoCardRef({
          ...infoCard,
          markerContainer: infoCardContainer,
          marker: infoCard ? createMarker(infoCard, infoCardContainer) : null,
        });
      } else {
        if (currentInfoCardRef) {
          currentInfoCardRef.markerContainer.removeEventListener(
            'dblclick',
            handleDoubleClickOnInfoCard
          );
          if (currentInfoCardRef.marker) {
            currentInfoCardRef.marker.remove();
          }
        }
        setCurrentInfoCardRef(null);
      }
    }
  }, [
    isMapReady,
    listings,
    activeListingId,
    infoCardOpen,
    onListingClicked,
    onListingInfoCardClicked,
    createURLToListing,
    mapComponentRefreshToken,
    createMarker,
    handleDoubleClickOnInfoCard,
  ]);

  return (
    <div
      id={id}
      ref={setMapContainer}
      className={classNames(className, css.fullArea)}
      onClick={onClick}
    >
      {isMapReady &&
        currentMarkersRef.current.map(m => {
          // Remove existing activeLabel classes and add it only to the correct container
          m.markerContainer.classList.remove(css.activeLabel);
          if (activeListingId && activeListingId.uuid === m.componentProps.key) {
            m.markerContainer.classList.add(css.activeLabel);
          }

          const isMapReadyForMarkers = mapRef.current && m.markerContainer;
          // DOM node that should be used as portal's root
          const portalDOMContainer = isMapReadyForMarkers
            ? document.getElementById(m.markerContainer.id)
            : null;

          // Create component portals for correct marker containers
          if (isMapReadyForMarkers && m.type === 'price') {
            const { key, ...priceLabelProps } = m.componentProps;
            return ReactDOM.createPortal(
              <SearchMapPriceLabel key={key} {...priceLabelProps} config={config} />,
              portalDOMContainer
            );
          } else if (isMapReadyForMarkers && m.type === 'group') {
            const { key, ...groupLabelProps } = m.componentProps;
            return ReactDOM.createPortal(
              <SearchMapGroupLabel key={key} {...groupLabelProps} />,
              portalDOMContainer
            );
          }
          return null;
        })}
      {isMapReady && currentInfoCardRef
        ? ReactDOM.createPortal(
            <SearchMapInfoCard
              key={currentInfoCardRef.key}
              {...currentInfoCardRef.componentProps}
              config={config}
            />,
            currentInfoCardRef.markerContainer
          )
        : null}
    </div>
  );
};

SearchMapWithMapbox.defaultProps = {
  id: 'map',
  center: null,
  priceLabels: [],
  infoCard: null,
  zoom: 11,
  reusableMapHiddenHandle: null,
};

SearchMapWithMapbox.propTypes = {
  id: string,
  center: propTypes.latlng,
  location: shape({
    search: string.isRequired,
  }).isRequired,
  priceLabels: arrayOf(node),
  infoCard: node,
  onClick: func.isRequired,
  onMapMoveEnd: func.isRequired,
  onMapLoad: func.isRequired,
  zoom: number,
  reusableMapHiddenHandle: string,
  config: object.isRequired,
};

export default SearchMapWithMapbox;
