import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { node, string, object } from 'prop-types';
import PropTypes from 'prop-types';

import { IntlProvider } from '../../../util/reactIntl';

import css from './SearchMap.module.css';

/**
 * ReusableMapContainer makes Google Map usage more effective. This improves:
 * - Performance: no need to load dynamic map every time user enters the search page view on SPA.
 * - Efficient Google Maps usage: less unnecessary calls to instantiate a dynamic map.
 * - Reaction to a bug when removing Google Map instance
 *   https://issuetracker.google.com/issues/35821412
 */
const ReusableMapContainer = ({
  children,
  className,
  reusableMapHiddenHandle,
  messages,
  config,
  onReattach,
}) => {
  const mountNodeRef = useRef(null);
  const elRef = useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!window.reusableSearchMapElement) {
        window.reusableSearchMapElement = document.createElement('div');
        window.reusableSearchMapRoot = null;
      }

      if (!className) {
        console.warn('ReusableMapContainer should get className prop which defines its layout');
      }
      const mapLayoutClassName = className || css.defaultMapLayout;

      elRef.current = window.reusableSearchMapElement;
      elRef.current.id = 'search-map';
      elRef.current.classList.add(mapLayoutClassName);

      // Create the root only once
      if (!window.reusableSearchMapRoot) {
        window.reusableSearchMapRoot = ReactDOM.createRoot(elRef.current);
      }
    }

    return () => {
      if (elRef.current) {
        elRef.current.classList.add(css.reusableMapHidden);
        elRef.current.classList.add(reusableMapHiddenHandle);
        mountNodeRef.current?.removeChild(elRef.current);
        document.body.appendChild(elRef.current);
      }
    };
  }, [className, reusableMapHiddenHandle]);

  useEffect(() => {
    const renderSearchMap = () => {
      const wrappedChildren = (
        <IntlProvider locale={config.localization.locale} messages={messages} textComponent="span">
          {children}
        </IntlProvider>
      );

      // Use the existing root to render
      if (window.reusableSearchMapRoot) {
        window.reusableSearchMapRoot.render(wrappedChildren);
      }

      const targetDomNode = document.getElementById(elRef.current.id);

      if (!targetDomNode) {
        if (mountNodeRef.current && !mountNodeRef.current.firstChild) {
          mountNodeRef.current.appendChild(elRef.current);
        } else if (!mountNodeRef.current) {
          document.body.appendChild(elRef.current);
        }
      } else {
        elRef.current.classList.remove(css.reusableMapHidden);
        elRef.current.classList.remove(reusableMapHiddenHandle);

        if (mountNodeRef.current && !mountNodeRef.current.firstChild) {
          document.body.removeChild(elRef.current);
          mountNodeRef.current.appendChild(elRef.current);
          onReattach();
        }
      }
    };

    renderSearchMap();
  }, [children, config, messages, reusableMapHiddenHandle, onReattach]);

  return <div className={css.reusableMap} ref={mountNodeRef} />;
};

ReusableMapContainer.propTypes = {
  children: node.isRequired,
  className: string,
  reusableMapHiddenHandle: string.isRequired,
  messages: object.isRequired,
  config: object.isRequired,
  onReattach: PropTypes.func.isRequired,
};

export default ReusableMapContainer;
