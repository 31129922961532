import React, { useRef, useCallback, useEffect } from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../../util/reactIntl';
import { Form } from '../../../components';
import css from './SearchForm.module.css';

const KeywordSearchField = ({ handleSubmit }) => {
  return (
    <div className={css.keywordSearchWrapper}>
      <Field
        name="keywords"
        render={({ input, meta }) => (
          <input
            className={css.keywordInput}
            {...input}
            id="keyword-search-search-page"
            type="text"
            placeholder="Search by keyword"
            autoComplete="off"
            onKeyPress={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                handleSubmit();
              }
            }}
          />
        )}
      />
    </div>
  );
};

const SearchFormComponent = ({ rootClassName, className, onSubmit, intl }) => {
  const formRef = useRef(null);
  const formApi = useRef(null);

  const handleSubmit = useCallback(
    values => {
      onSubmit(values);
    },
    [onSubmit]
  );

  useEffect(() => {
    const handleClickOutside = event => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        if (formApi.current) {
          const formState = formApi.current.getState();
          if (formState.dirty) {
            formApi.current.submit();
          }
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <FinalForm
      onSubmit={handleSubmit}
      render={({ handleSubmit: handleFormSubmit, form }) => {
        // Store the form API for use in the click outside handler
        formApi.current = form;

        return (
          <Form className={classes} onSubmit={handleFormSubmit}>
            <div ref={formRef}>
              <KeywordSearchField intl={intl} handleSubmit={handleFormSubmit} />
            </div>
          </Form>
        );
      }}
    />
  );
};

SearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

SearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  intl: intlShape.isRequired,
};

const SearchForm = injectIntl(SearchFormComponent);

export default SearchForm;
